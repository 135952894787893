import type { I18nHeadMetaInfo } from '@nuxtjs/i18n';
import { UseSeoMetaInput } from '@unhead/vue';

import type { AiPicture, SeoMetadata } from '~/domains/graphql';

const MAX_DESC_LENGTH = 156;

export type Metadata = SeoMetadata | undefined | null;

export type HeadLink = {
  hid: string;
  rel: 'alternate' | 'canonical';
  href: string;
  hreflang?: string;
};

export type UseSeoMetadataInput = {
  metadata?: Metadata | Ref<Metadata>;
  fallback?: SeoMetadata | Ref<SeoMetadata>;
};

function trim(value: string | undefined): string | undefined {
  if (!value || typeof value !== 'string') return value;

  if (value.length <= MAX_DESC_LENGTH) return value;

  return value.slice(0, MAX_DESC_LENGTH - 3).concat('...');
}

function extract<T>(
  meta: UseSeoMetadataInput['metadata'],
  property: keyof SeoMetadata,
): T {
  if (isRef(meta)) {
    return meta.value?.[property] as T;
  }

  return meta?.[property] as T;
}

function pathJoin(...paths: string[]): string {
  return paths.reduce((accumulator, path) => {
    let url = accumulator;

    if (!url.endsWith('/')) url += '/';

    if (path.startsWith('/')) {
      url += path.slice(1, path.length);
    } else {
      url += path;
    }

    if (!url.endsWith('/')) url += '/';

    return url;
  });
}

/**
 * @see https://jira.kaliop.net/browse/ACCORDAI-1456
 */
function alignHeadLinkQueryParameters(links: HeadLink[]): HeadLink[] {
  const canonicalIndex = links.findIndex(link => link.rel === 'canonical');

  if (canonicalIndex < 0) return links;

  const alternateLinks = links.filter(link => link.rel === 'alternate');
  const alternateQueryParametersSearch = alternateLinks.reduce((set, link) => {
    const linkUrl = new URL(link.href);
    set.add(linkUrl.search);

    return set;
  }, new Set<string>());

  const linksClone = JSON.parse(JSON.stringify(links)) as HeadLink[];

  const linksHaveSameQueryParameters =
    alternateQueryParametersSearch.size === 1;

  const queryParameters = Array.from(
    alternateQueryParametersSearch.values(),
  )[0];

  // If there is the same query parameters across all alternate link,
  // replicate it to the canonical URL
  // If alternate links have differents query parameters, remove them for consistency
  for (let i = 0; i < links.length; i++) {
    if (linksHaveSameQueryParameters && i === canonicalIndex) {
      links[i].href = pathJoin(
        linksClone[canonicalIndex].href,
        queryParameters,
      );
    } else if (!linksHaveSameQueryParameters) {
      links[i].href = linksClone[i].href.replace(queryParameters, '');
    }
  }

  return links;
}

export function useSeoMetadata({
  metadata,
  fallback,
}: UseSeoMetadataInput = {}) {
  const config = useRuntimeConfig();

  const i18nHead: Ref<I18nHeadMetaInfo> = useLocaleHead({
    addSeoAttributes: true,
  });

  const canonicalAndAlternateLinks = computed(() => {
    const links =
      i18nHead.value.link?.filter(
        // Removes translations debug links
        link => !['xx', 'xx-XX'].includes(link.hreflang),
      ) || [];

    const builtLinks = links.map(link => {
      if (link.href.startsWith(config.public.baseUrl)) return link;

      return {
        ...link,
        href: pathJoin(`${config.public.baseUrl}`, link.href),
      };
    });

    return alignHeadLinkQueryParameters(builtLinks as HeadLink[]);
  });

  const seo = computed(() => {
    const description = extract<string>(metadata, 'description');
    const ogDescription = extract<string>(metadata, 'ogDescription');
    const ogTitle = extract<string>(metadata, 'ogTitle');
    const ogImage = extract<AiPicture>(metadata, 'ogImage')?.src;

    const seoHead = {
      description: trim(
        description || extract<string>(fallback, 'description'),
      ),
      ogDescription:
        ogDescription ||
        extract<string>(fallback, 'ogDescription') ||
        extract<string>(fallback, 'description'),
      ogTitle:
        ogTitle ||
        extract<string>(fallback, 'ogTitle') ||
        extract<string>(fallback, 'title'),
      ogImage: ogImage || extract<AiPicture>(fallback, 'ogImage')?.src,
    };

    // Removes null/undefined values to prevent flakky header
    for (const property in seoHead) {
      if (!seoHead[property as keyof typeof seoHead]) {
        delete seoHead[property as keyof typeof seoHead];
      }
    }

    return seoHead;
  });

  const head = computed(() => {
    const title = extract<string>(metadata, 'title');

    return {
      title: title || extract<string>(fallback, 'title'),
      link: canonicalAndAlternateLinks.value,
    };
  });

  useHead(head);
  useSeoMeta(seo as unknown as UseSeoMetaInput);

  return head;
}
